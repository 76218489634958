<script setup lang="ts">
const { $toast } = useNuxtApp();

const { signIn, data } = useAuth()
//************************************************************ login form */
const form = ref({
  username: undefined,
  password: undefined,
});
const showPassword = ref(false);
const isValid = ref(true);
const isLoading = ref(false);
const usernameRules = ref([(v: any) => !!v || "Username is required"]);
const passwordRules = ref([(v: any) => !!v || "Password is required"]);

const loginForm = ref(null);

const handleSignIn = async () => {
  const { valid } = await loginForm.value!.validate();

  if (!valid) {
    console.log(`[LoginForm.handleSignIn] form not validated`);
    return;
  }
  try {
    isLoading.value = true;
    await signIn({ username: form.value.username, password: form.value.password }, {
      callbackUrl: '/tasks'
    })
  } catch (err) {
    isLoading.value = false;
    console.error(
      "?delete catch block? [LoginForm.handleSignIn] !!UGLY!! err: ",
      err
    );
    $toast.error('Неверный логин или пароль. Возможно пользователь заблокирован');
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <div>
    <v-card width="400"
            class="mx-auto mt-5">
      <v-card-text>
        <v-form ref="loginForm"
                v-model="isValid"
                @submit.prevent="handleSignIn"
                id="login-form">
          <v-text-field label="Логин"
                        clearable
                        prepend-inner-icon="mdi-account-circle"
                        v-model="form.username"
                        :rules="usernameRules"
                        autocomplete="username" />
          <v-text-field label="Пароль"
                        clearable
                        :type="showPassword ? 'text' : 'password'"
                        prepend-inner-icon="mdi-lock"
                        :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:appendInner="showPassword = !showPassword"
                        v-model="form.password"
                        :rules="passwordRules"
                        autocomplete="current-password" />
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn :loading="isLoading"
                   color="success"
                   size="large"
                   type="submit"
                   block
                   :disabled="!isValid"
                   variant="elevated">Войти</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
